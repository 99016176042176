import React from "react"

import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"
import Submenutreneri from "../../components/submenutreneri/submenutreneri"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import "./../treneri.scss"

import ImgTreneriZabystrzan from "../../images/treneri/petr-zabystrzan.jpg"

const PetrZabystrzan = () => (

	<div>

		<Layout>
			<Seo title="Petr Zabystrzan" description="Petr Zabystrzan" />

			<div className="stk-container stk-container--top-margin">

				<Row>
					<Col xs="12" xl="9">
						<div class="stk-trener__header">
							<div>
								<img class="stk-trener__image" src={ImgTreneriZabystrzan} alt="Petr Zabystrzan" />
							</div>
							<div>
								<h1 className="stk-h1">Petr Zabystrzan</h1>
								<p>Trenér - standardní tance</p>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--facebook" href="https://www.facebook.com/profile.php?id=100008405626729" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faFacebookF} />
								</a>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--phone" href="tel:+420737837853" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faPhone} />
								</a>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--email" href="mailto:petr.zabystrzan@seznam.cz" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faEnvelope} />
								</a>
							</div>
						</div>
						<ul>
							<li>Mistr ČR ve&nbsp;standardních tancích, čtvrtfinalista mistrovství světa v&nbsp;amatérské a&nbsp;profesionální kategorii</li>
							<li>Porotce &amp; trenér I.&nbsp;třády</li>
						</ul>
					</Col>
					<Col xs="12" xl="3">
						<Submenutreneri></Submenutreneri>
					</Col>
				</Row>
			</div>
		</Layout>

	</div>

)

export default PetrZabystrzan